/* eslint-disable no-inner-declarations, import/newline-after-import, import/first, no-alert */
import version from '../version.json'
console.log('fed version:', version.major + '.' + version.minor + '.' + version.patch)

window.DEV_ENV = process.env.NODE_ENV === 'development' || location.origin.includes('local')

import moment from 'moment'
window.moment = moment

import prettifyJson from '../../prettifyJson'
window.prettifyJson = prettifyJson

console.verbose = console.debug.bind(console)

// This allows certain calls to omit this component stack trace:
// modify certain console methods so that...
// Seems to be causing performance issue in ie
const consoleWithComponentStacks = window.console
// react/devtools can add component stack traces to console.error/warn/trace calls.
// backup references to original methods before react overrides (in development)
// https://github.com/facebook/react/blob/206d61f72214e8ae5b935f0bf8628491cb7f0797/packages/react-devtools-shared/src/backend/console.js#L16
consoleWithComponentStacks.original_error = window.console.error
consoleWithComponentStacks.original_warn = window.console.warn
consoleWithComponentStacks.original_trace = window.console.trace

window.alreadyReportedErrors = {}
console.reportError = (...args) => {
  // console.log('args', args, 'omitStackTrace', omitStackTrace)
  if (args[args.length - 1] === 'omit-component-stack') {
    args.pop()
    window.originalConsole.original_error(...args)
  } else {
    // react may add component stacks when we call normal console.error/trace/warn:
    // there is a global setting for that inside the React Components dev tools pane (inside chrome dev tools)
    window.console.error(...args)
  }
  if (window.newrelic) {
    const fullErrorMessage = args
      .map(arg =>
        typeof arg === 'object' // && !location.origin.includes('www.myhealthpaysrewards.com')
          ? prettifyJson(arg)
          : arg.toString()
      )
      .join(' ')
    if (!window.alreadyReportedErrors[fullErrorMessage]) {
      window.newrelic.noticeError(new Error(fullErrorMessage))
      window.alreadyReportedErrors[fullErrorMessage] = true
    }
  }
  return args.join(' ') // not sure what this would be used for, since we now include
}

window.alreadyLoggedArgStrings = {}
;['error', 'warn', 'trace', 'reportError', 'verbose', 'debug', 'log'].forEach(method => {
  window.alreadyLoggedArgStrings[method] = {}
  window.console[method + 'Once'] = function console_method_call_once(...args) {
    const argString = args.join(' ')
    if (!window.alreadyLoggedArgStrings[method][argString]) {
      console[method](...args)
      window.alreadyLoggedArgStrings[method][argString] = true
    }
  }

  // you can pass "omit-component-stack" as last argument, and have the component stack omitted for just that call,
  if (consoleWithComponentStacks['original_' + method]) {
    window.console[method] = function console_support_omit_component_stack(...args) {
      if (args[args.length - 2] === 'omit-component-stack') {
        // console.log('you appear to be overriding window.console methods')
        // ensure last arg IS a component stack:
        if (!args[args.length - 1].includes('   in ')) {
          window.console.log(
            'confused - this last arg doesnt appear to be a component stack, but "omit-component-stack" is not last argument. last arg:',
            args[args.length - 1],
            '\n continuing to call console.' + method + '...'
          )
        }

        // react dev tools enabled, and caller requested component stack be omitted
        // now remove  the component stack from react/devtools
        args.pop()
      }
      if (args[args.length - 1] === 'omit-component-stack') {
        args.pop()
        window.originalConsole['original_' + method](...args)
      } else {
        // react may add component stacks when we call normal console.error/trace/warn:
        // there is a global setting for that inside the React Components dev tools pane (inside chrome dev tools)
        consoleWithComponentStacks['original_' + method](...args)
      }
    }
  }
})

if (window.NREUM && !window.newrelic) {
  console.warn('using older window.NREUM api')
  window.newrelic = window.NREUM
}

// make sure we dont crash if window.newrelic is defined but not .noticeError
if (window.newrelic && !window.newrelic.noticeError) {
  // just avoid crashing
  window.newrelic.noticeError = () => {}
}

const nrFailureCount = parseInt(localStorage.getItem('nrFailureCount') || '0', 10)
if (!window.newrelic) {
  localStorage.setItem('nrFailureCount', String(nrFailureCount + 1))
} else if (nrFailureCount > 0) {
  console.reportError('new relic load failures so far:' + nrFailureCount)
  localStorage.setItem('nrFailureCount', '0')
}

window.pendingRequestMeta = {
  pendingRequests: {},
  pendingRequestUrls: {},
  pendingLocationChange: undefined,
  beforeUnloadListeners: {},
  currentlyRedirecting: false,
  considerPendingLocationChange: () => {
    if (
      Object.keys(window.pendingRequestMeta.pendingRequests).length === 0 &&
      window.pendingRequestMeta.pendingLocationChange
    ) {
      window.pendingRequestMeta.currentlyRedirecting = true
      requestAnimationFrame(() => {
        setTimeout(() => {
          requestAnimationFrame(() => {
            window.pendingRequestMeta.pendingLocationChange()
          })
        }, 50)
      })
    }
  },
}

// prevent simultaneous requests. Should usually return undefined, preventing a popup from being shown.
// should rarely be seen if everyone properly uses requestLocationChange
// therefore this acts as a sort of insurance policy.
// If you click into a new page and then reload the page before the loading state is resolved, you'll see this popup.
// window.addEventListener('beforeunload', event => {
//   const pendingRequestKeys = Object.keys(window.pendingRequestMeta.pendingRequests)
//   const numPendingRequests = pendingRequestKeys.length
//   if (window.pendingRequestMeta.currentlyRedirecting) {
//     return
//   }
//   let msg
//   if (numPendingRequests !== 0) {
//     console.reportError(numPendingRequests + ' REQUEST(S) PENDING: ' + pendingRequestKeys.join('\n'))
//     event.preventDefault()
//     msg = 'Request is pending. Leaving page may cause errors.'
//     event.returnValue = msg
//     return msg
//   } else if (window.DEV_ENV && localStorage.getItem('enable-beforeunload')) {
//     msg = 'Developer: Do you want to lose all console state and load new page?'
//     console.log(msg)
//     event.preventDefault()
//     event.returnValue = msg
//     return msg
//   }
//   // dont show popup/allow user to leave page
// })

function checkHash() {
  if (location.hash === '#language-debug' || location.hash === '#enable-language-debug') {
    localStorage.setItem('language-debug', 'true')
    localStorage.setItem('disable-redux-logging', 'true') // cleanup logs if you reload page
    ;(document.getElementsByClassName('language-bar')[0] as HTMLElement).style.display = 'block'
    window.languageDebug = true
    console.log('enabled language debug mode')
  }
  if (location.hash === '#disable-language-debug') {
    localStorage.setItem('language-debug', 'false')
    localStorage.removeItem('disable-redux-logging') // cleanup logs if you reload page
    ;(document.getElementsByClassName('language-bar')[0] as HTMLElement).style.display = 'none'
    window.languageDebug = false
    console.log('disabled language debug mode')
  }
}
if (localStorage.getItem('language-debug') === 'true') {
  window.languageDebug = true
}

function checkHashSettings(e = undefined) {
  checkHash()
  if (e && e.newURL && e.oldURL) {
    if (e.newURL.includes('language-debug') || e.oldURL.includes('language-debug')) {
      location.hash = ''
    }
  }
}
checkHashSettings()
window.onhashchange = checkHashSettings

// stub - this function takes a callback like ()=>{location.href="/foo"}
// it is later modified when ajax is initialized
// it's purpose is to not redirect when an ajax request is pending
window.requestLocationChange = function stub_requestLocationChange(callback, redirectToMsg) {
    window.currentlyRedirecting = true
    return callback()
}

import custom_feature_flags from '../custom_feature_flags'
import default_feature_flags from './default_feature_flags'
let localStorageFFOverrides: any = localStorage.getItem('FeatureFlagOverrides')
if (localStorageFFOverrides) {
  localStorageFFOverrides = JSON.parse(localStorageFFOverrides)
}

window.feature_flags = {
  ...default_feature_flags,
  ...custom_feature_flags,
  localStorageFFOverrides,
}
// for easier testing
window.setLocalStorageFeatureFlagOverride = (key, value) => {
  if (value) {
    localStorageFFOverrides[key] = value
  } else {
    // removeLocalStorageFeatureFlagOverride('feature_a')
    delete localStorageFFOverrides[key]
  }
  localStorage.setItem('FeatureFlagOverrides', JSON.stringify(localStorageFFOverrides))
  window.reload(true)
}
window.removeLocalStorageFeatureFlagOverride = window.setLocalStorageFeatureFlagOverride

// START INIT DATA MAYBE-FETCH LOGIC
function initDataMain() {
  function loadApp() {
    import(/* webpackChunkName: 'app-jsx' */ './app.jsx').catch(console.reportError)
  }

  if (location.href.includes('use-proxy=1')) {
    // won't work until this is deployed to the remote server! :(
    console.reportError('page url has use-proxy=1 - this should never be the case')
    // location.href = '/';
  }

  const initData = document.getElementById('initData').innerHTML

  if (localStorage.getItem('use_qa_api')) {
    window.qaOrigin = localStorage.getItem('use_qa_api')
  } else if (window.DEV_ENV) {
    console.verbose('defaulting localStorage.use_qa_api to empty string.')
    window.qaOrigin = ''
  }

  if (window.qaOrigin != null && window.DEV_ENV) {
    // make sure qaOrigin doesn't have trailing slash
    if (window.qaOrigin[window.qaOrigin.length - 1] === '/') {
      window.qaOrigin = window.qaOrigin.substring(0, window.qaOrigin.length - 1)
    }

    // however qaOrigin was set, make sure its saved in localStorage
    localStorage.setItem('use_qa_api', window.qaOrigin)

    let afterOrigin = location.pathname + location.search

    let pathname_overrides = localStorage.getItem('pathname_overrides')
    if (pathname_overrides) {
      pathname_overrides = JSON.parse(pathname_overrides)
      Object.keys(pathname_overrides).forEach(newPageUrl => {
        if (afterOrigin.includes(newPageUrl)) {
          afterOrigin = afterOrigin.replace(newPageUrl, pathname_overrides[newPageUrl])
          console.log('using ' + afterOrigin + ' init json data for ' + newPageUrl)
        }
      })
    }
    const qaPageUrlObject = new URL(window.qaOrigin + afterOrigin, location.href)
    // add ?use-proxy=1
    qaPageUrlObject.searchParams.set('use-proxy', '1')
    const qaPageUrl = qaPageUrlObject.toString()

    if (qaPageUrl.includes('/logout?use-proxy=1')) {
      window.requestLocationChange(() => {
        location.href = '/'
      }, 'to / (index)')
      console.reportError('location.href is /logout?use-proxy=1\n', 'redirecting to / ')
      return
    }

    // pegasus is a super tiny ajax library
    pegasus_timeout = 1000 * 30
    console.log('using qa api, fetching: ' + qaPageUrl)
    console.group('attempting to initialize...')

    const generateRequestCompleteFn = function generateRequestCompleteFn(successState) {
      return function initDataRequestComplete(pageHtml, xhr) {
        if (successState === 'error') {
          console.groupEnd() // attempting to initialize...
          console.reportError(successState + ' - xhr:', xhr)
        }

        if (successState === 'success') {
          const lastIndex = 0
          let json
          const grabJson = function grabJson(tagId) {
            const tag = '<script id="' + tagId + '" type="application/json">'
            const startIndex = pageHtml.indexOf(tag, lastIndex) + tag.length
            const endIndex = pageHtml.indexOf('</script>', startIndex)
            json = pageHtml.substring(startIndex, endIndex)
            json = JSON.parse(json)
            return json
          }

          try {
            window.initDataJson = grabJson('initData')
            window.systemDataFromQa = grabJson('system')
          } catch (e) {
            console.log("Error in XHR call for initData", e);
            return
          }
          console.log('initial system data json:', window.systemDataFromQa)
          console.log('initial app state json:', window.initDataJson)
        }

        loadApp()
      }
    }
    pegasus(qaPageUrl).then(generateRequestCompleteFn('success'), generateRequestCompleteFn('error'))
  } else {
    if (
      initData.includes(
        '<!-- ' +
          'appState' +
          /* must be separated so that it doesn't get replaced on server */
          ' -->'
      )
    ) {
      console.log(
        'Init data is unchanged, so this must be index page on dev server. ' +
          // "If that's not the case, this is strange edge case you may not want to be in... " +
          // "\nI used to think that you don't need to request data in this case.. but you actually do " +
          // "\nhopefully there's no need request init data from qa api. " +
          '\nYou may want to set a qa api via: ' +
          "\n  localStorage.setItem('use_qa_api', 'https://your-project-qa.performnet.com')\n" +
          (window.qaOrigin
            ? '\n\nUsing this qa url in use_qa_api anyway:' + window.qaOrigin
            : "\n\nTo force use qa api, run: localStorage.setItem('use_qa_api', 'https://your-project-qa.performnet.com')")
      )
    } else {
      console.log('not using qa api')
    }
    loadApp()
  }
}
initDataMain()

// pegasus.js, 0.2kb ajax lib:
// a   url (naming it a, because it will be reused to store callbacks)
// e   timeout error placeholder to avoid using let, not to be used
// xhr placeholder to avoid using let, not to be used
let pegasus_timeout
function pegasus(a, e = undefined, xhr = undefined) {
  /* eslint-disable */
  xhr = new XMLHttpRequest()

  // Set URL
  xhr.open('GET', a)
  if (
    localStorage.getItem('use_qa_api') !== '' &&
    !localStorage.getItem('use_qa_api').includes(location.origin)
  ) {
    xhr.withCredentials = true
  }

  // Don't need a to store URL anymore
  // Reuse it to store callbacks
  a = []

  pegasus_timeout && (xhr.timeout = pegasus_timeout)

  xhr.ontimeout = function(event) {
    e = event
  }

  xhr.onreadystatechange = xhr.then = function xhrThenOnreadyStateChange(onSuccess, onError, cb, data) {
    // Test if onSuccess is a function
    // Means that the user called xhr.then
    if (onSuccess?.call) {
      a = [, onSuccess, onError]
    }

    // Test if there's a timeout error
    e && a[2] && a[2](e, xhr)

    // Test if request is complete
    if (xhr.readyState == 4) {
      // index will be:
      // 0 if undefined
      // 1 if status is between 200 and 399
      // 2 if status is over
      cb = a[0 | (xhr.status / 200)]
      if (cb) {
        cb(xhr.responseText, xhr)
      }
    }
  }

  xhr.onerror = function xhrOnerror() {
    console.reportError('xhr onerror... xhr:', (window.failedXhr = xhr), 'check window.failedXhr')
  }

  xhr.send()
  window.lastPegasusXhr = xhr
  // Return request
  return xhr
}
window.pegasus = pegasus
